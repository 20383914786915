<template>
<div class="tgju-widget-content full-padding">
    <div :class="'tgju-widget light min-h-unset ' + ( isMobile ? 'widget-mobile-clear' : '')">
        <div class="list-widget  list-row bold list-row-title">
            <div class="list-col l-title dot head-color no-border">
                <span>دارایی‌ها</span>
            </div>
            <div class="list-col l-item head-color" v-tooltip="'بروزرسانی'">
                <i class="uil uil-sync pointer" @click="getInventory()"></i>
            </div>
            <div v-if="!isMobile" class="list-col l-item head-color">
                <i class="uil uil-wallet"></i>
            </div>
        </div>
        <div class="tgju-widget-content full-padding">
            <slot v-if="widgetLoad">
                <div class="text-center p-2">
                    <div class="widget-loading"></div>
                </div>
            </slot>
            <slot v-else>
                <table v-if="inventories.length" class="widget-table widget-table-lg">
                    <thead>
                        <tr>
                            <th class="text-center">عنوان شاخص</th>
                            <th class="text-center">تعداد</th>
                            <th class="text-center">ارزش واحد</th>
                            <th class="text-center hide-480">ارزش کل</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="inventory in inventories" :key="inventory.id">
                            <td class="border-l text-center">
                                {{ inventory.label }}
                            </td>
                            <td class="border-l text-center">
                                {{ formatPrice(inventory.amount) }}
                            </td>
                            <td class="border-l text-center">
                                {{ formatPrice(getPrice(inventory.asset))}}
                            </td>
                            <td class="border-l text-center hide-480">
                                {{ formatPrice(getPrice(inventory.asset) * inventory.amount) }}
                            </td>
                        </tr>
                    </tbody>
                </table>
                <slot v-else>
                    <div class="popup-dash-empty">
                        <i class="uil uil-wallet" aria-hidden="true"></i>
                        <span>مقداری برای نمایش وجود ندارد</span>
                    </div>
                </slot>
            </slot>
        </div>
    </div>
</div>
</template>

<style lang="scss" scoped>
thead {
    display: table-header-group;
    vertical-align: middle;
    border-color: inherit;
}
</style>

<script>
// این کامپوننت دارای اجزای دارایی های سرمایه گذاری کاربر می باشد
import moment from "moment-jalaali";
// import Paginate from "vuejs-paginate";

export default {
    name: 'InventoryList',
    components: {
        // Paginate
    },
    data: function () {
        return {
            isMobile:false,
            inventories: [],
            prices: [],
            widgetLoad: true,
        }
    },
    mounted() {
        this.getInventory();
        if (Vue.prototype.$helpers.checkDevice('app')) {
            this.isMobile= true;
        }
    },
    methods: {
        // این متد جهت نمایش مناسب زمان مورد استفاده قرار میگیرد
        toRelative(date) {
            return moment(date).fromNow();
        },
        // این متد جهت نمایش بهتر نرخ ها و قیمت ها می باشد // جدا کننده قیمت با کاما
        formatPrice(value) {
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        // این متد برای ارسال درخواست به سرور جهت دریافت اصلاعات دارایی کاربرو سپس ارسال درخواست به سرور جهت دریافت اخرین نرخ هایی دارایی کاربر
        getInventory() {
            // ارسال درخواست ای پی آی
            this.widgetLoad = true,
                this.$helpers.makeRequest('GET', '/investment/my-inventory/true').then(api_response => {
                    this.$helpers.api_alert(api_response);

                    this.inventories = api_response.data.response.inventory;
                    var assets = this.inventories.map(function (value) {
                        return value.asset;
                    });

                    if (assets.length) {
                        let api_data = {
                            market_ids: assets.join(),
                        };

                        this.$helpers.makeRequest('GET', '/watchlist/market', api_data).then(api_response => {
                            this.$helpers.api_alert(api_response);
                            var assets_value = api_response.data.response.detail;

                            this.prices = assets_value.map(function (value) {
                                return { 'itemId': value.item_id, 'amount': value.p, };
                            });
                        });
                    }
                    this.widgetLoad = false;
                });
        },
        // این متد جهت یافت قیمت شاخص در ارایه مورد نظر استفاده میشود
        getPrice(symbol) {
            var amount = 0;
            this.prices.forEach((item) => {
                if (item.itemId == symbol) {
                    amount = item.amount;
                    return false;
                }
            })
            return amount;
        },
    },
}
</script>
